<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon class="mb-5" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
        <template>
            <v-form @submit.prevent="getClients('search')">
                <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <div class="mb-10">
                        <TabsNav />
                        <v-row>
                            <v-col>
                                <h1>
                                    {{ filter_show ? $t('search_client') : $t('client') }}
                                </h1>
                            </v-col>
                        </v-row>
                        <v-row v-if="filter_show">
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider
                                    ref="client"
                                    rules="min:1|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <TextInput
                                        id="client"
                                        v-model="client"
                                        :error-messages="errors"
                                        :label="$t('client_name')"
                                        :disabled="loading"
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        clearable
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider
                                    ref="phone"
                                    rules="phone"
                                    v-slot="{ errors, valid }"
                                >
                                    <TextInput
                                        id="phoneRaw"
                                        v-model="phoneRaw"
                                        type="tel"
                                        v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                        :error-messages="errors"
                                        :label="$t('phone')"
                                        :disabled="loading"
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        clearable
                                    >
                                    </TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider
                                    ref="email"
                                    rules="email|min:5|max:50"
                                    v-slot="{ errors, valid }"
                                >
                                    <TextInput
                                        id="email"
                                        v-model="email"
                                        type="email"
                                        :error-messages="errors"
                                        :label="$t('email')"
                                        :disabled="loading"
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                    >
                                    </TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <SelectInput
                                    id="active"
                                    v-model="active"
                                    :items="activeItems"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('active')"
                                    :disabled="loading"
                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                ></SelectInput>
                            </v-col>
                        </v-row>
                        <v-row v-if="filter_show">
                            <v-col cols="12" sm="3" md="2">
                                <SelectInput
                                    id="perPage"
                                    :value="perPage"
                                    :items="perPageItems"
                                    :label="$t('items_per_page')"
                                    @input="perPage = options.itemsPerPage = Number($event)"
                                    :disabled="loading"
                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                    color="primary"
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                ></SelectInput>
                            </v-col>
                            <v-col cols="12" sm="9" md="10" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" class="infinity_button">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </ValidationObserver>
            </v-form>

            <ResizableTable
                class="main-data-table elevation-0 uveds-table"
                :rows="clients"
                :columns="filteredHeaders.map((item) => {
                    return {
                        prop: item.value,
                        label: item.text,
                        width: 'auto'
                    }
                })"
            >
                <template v-slot:name="{ item }">
                    <div :class="item.deleted ? 'red--text' : ''">
                        <span class="cursor-pointer font_weight_600" @click="editUser(item)">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </span>
                        <v-icon v-if="!item.deleted" @click="deleteUser(item)" small>mdi-delete-outline</v-icon>

                    </div>
                </template>
                <template v-slot:phone="{ item }">
                    <div>{{ formatPhoneNumber(item.phone) }}</div>
                </template>
                <template v-slot:email="{ item }">
                    <div>{{ item.email }}</div>
                </template>
                <template v-slot:active="{ item }">
                    <div>{{ item.active ? $t('yes') : $t('no') }}</div>
                </template>
                <template v-slot:action="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="editUser(item)" icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="item.deleted" @click="deleteUser(item)" icon>
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                    </v-tooltip>
                </template>
            </ResizableTable>

            <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
        </template>
    </PageLayout>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: "Clients",
    directives: {
        mask,
    },
    components: {
        TabsNav,
        PageLayout,
        ValidationProvider,
        ValidationObserver,
        TextInput,
        SelectInput
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            client: null,
            email: null,
            clients: [],
            clientItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalClients: 0,
            options: {},
            loading: false,
            loadingCities: false,
            city: null,
            cityItems: [],
            citySearching: null,
            headers: [
                {
                    text: this.$t('client'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('phone'),
                    align: "left",
                    sortable: true,
                    value: "phone"
                },
                {
                    text: this.$t('email'),
                    align: "left",
                    sortable: true,
                    value: "email"
                },
                {
                    text: this.$t('active'),
                    align: "left",
                    sortable: true,
                    value: "active",
                },
            ],
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            Additional: [
                {'title': 'База Казахстан', 'count': '10'},
                {'title': 'База Россия', 'count': '10'},
                {'title': 'База Узбекистан', 'count': '10'},
            ],
            group1: true,
            group2: true,
            filter_show: false,
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getClients()
            },
            deep: false
        }
    },
    methods: {
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        editUser(item) {
            this.$router.push({
                name: 'client.profile',
                params: {
                    id: item.id
                }
            })
        },
        async deleteUser(item) {
            if (confirm(this.$t('delete_client'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/client/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('client_has_been_deleted'))
                        this.getClients()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('client_has_not_been_deleted'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getClients(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.client) {
                params.client = this.client;
            }
            if (this.phone) {
                params.phone = this.phone;
            }
            if (this.email) {
                params.email = this.email;
            }
            params.active = this.active

            await this.$http
                .get("admin/client", {
                    params: params,
                })
                .then(res => {
                    this.clients = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalClients = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.clients = []
                    this.totalClients = 0
                    this.$toastr.error(this.$t('failed_to_get_list_clients'))
                })
                .finally(end => {
                    this.loading = false
                })
        }
    }
}
</script>
